$(document).ready(() => {
    const second = 1000;
    const el = document.getElementById('faudi-clock');

    let distance;

    const secondElement = document.getElementById('flip-seconds');
    const yearElement = document.getElementById('flip-years');
    const monthElement = document.getElementById('flip-months');
    const weekElement = document.getElementById('flip-weeks');
    const daysElement = document.getElementById('flip-days');
    const hoursElement = document.getElementById('flip-hours');
    const minutesElement = document.getElementById('flip-minutes');

    function isLeap(Year) {
        return (Year % 4 === 0 && Year % 100 !== 0) || (Year % 400 === 0);
    }

    function calcTimeVal(secs, timeUnitDiv, nextBiggerTimeUnitDiv) {
        return ((Math.floor(secs / timeUnitDiv)) % nextBiggerTimeUnitDiv);
    }

    const DaysInMonth = [
        31, 28, 31,
        30, 31, 30,
        31, 31, 30,
        31, 30, 31];

    if (typeof (el) !== 'undefined' && el !== null && el.dataset.flip === 'true') {

        const date = new Date(el.dataset.date).getTime();
        const fullpageData = el.dataset.fullpage;
        if (fullpageData !== 'true') {
            el.classList.add('boxed');
        }

        const x = setInterval(() => {
            const now = new Date();
            if (el.dataset.mode === 'DESC') {
                distance = (date - now.getTime()) / second;
            }
            if (el.dataset.mode === 'ASC') {
                distance = (now.getTime() - date) / second;
            }

            const Remain = {
                Y: 0,
                N: 0,
                W: 0,
                D: calcTimeVal(distance, 86400, 100000),
                H: calcTimeVal(distance, 3600, 24),
                M: calcTimeVal(distance, 60, 60),
                S: calcTimeVal(distance, 1, 60),
            };

            for (let thisYear = now.getFullYear();
                Remain.D >= (isLeap(thisYear + 1) ? 366 : 365); thisYear += 1) {
                Remain.D -= isLeap(thisYear + 1) ? 366 : 365;
                Remain.Y += 1;
            }

            for (let thisMonth = now.getMonth();
                Remain.D >= DaysInMonth[thisMonth]; thisMonth += 1) {
                Remain.D -= thisMonth === 1 && isLeap(now.getFullYear())
                    ? DaysInMonth[thisMonth] + 1 : DaysInMonth[thisMonth];
                Remain.N += 1;
                if (thisMonth === 11) thisMonth = 0;
            }

            if (Remain.D >= 7) {
                Remain.W = Math.floor(Remain.D / 7);
                Remain.D %= 7;
            }

            if (typeof (yearElement) !== 'undefined' && yearElement !== null) {
                yearElement.innerText = Remain.Y;
            }
            if (typeof (monthElement) !== 'undefined' && monthElement !== null) {
                monthElement.innerText = Remain.N;
            }
            if (typeof (weekElement) !== 'undefined' && weekElement !== null) {
                weekElement.innerText = Remain.W;
            }
            if (typeof (daysElement) !== 'undefined' && daysElement !== null) {
                daysElement.innerText = Remain.D;
            }
            if (typeof (hoursElement) !== 'undefined' && hoursElement !== null) {
                hoursElement.innerText = Remain.H;
            }
            if (typeof (minutesElement) !== 'undefined' && minutesElement !== null) {
                minutesElement.innerText = Remain.M;
            }
            if (typeof (secondElement) !== 'undefined' && secondElement !== null) {
                secondElement.innerText = Remain.S;
            }

            if (distance <= 0 && el.dataset.mode === 'DESC') {
                clearInterval(x);
                document.getElementById('clock-numbers').style.display = 'none';
                document.getElementById('countdown-done').style.display = 'block';
            }
        }, second);
    }
});

/*
Aufbau des HTML Elements in TYPO3
<div id="faudi-clock"
    data-fullpage="true"
    data-flip="true"
    data-mode="DESC"
    data-date="Feb 05, 2020 20:15:00"
    >
  <h2 id="head">COUNTDOWN / COUNTER</h2>
  <div id="clock-numbers">
    <div class="cd-box">
    <p id="flip-years" class="numbers"></p>
    <p class="timeString">Years</p></div>
    <div class="cd-box">
    <p id="flip-months" class="numbers"></p>
    <p class="timeString">Months</p>
    </div>
    <div class="cd-box">
    <p id="flip-weeks" class="numbers"></p>
    <p class="timeString">Weeks</p>
    </div>
    <div class="cd-box">
    <p id="flip-days" class="numbers"></p>
    <p class="timeString">Day</p>
    </div>
    <div class="cd-box">
    <p id="flip-hours" class="numbers"></p>
    <p class="timeString">Hours</p>
    </div>
    <div class="cd-box">
    <p id="flip-minutes" class="numbers"></p>
    <p class="timeString">Minutes</p>
    </div>
    <!--div class="cd-box">
    <p id="flip-seconds" class="numbers"></p>
    <p class="timeString">Seconds</p>
    </div-->
  </div>
  <div id="countdown-done" style="display: none">
    <h4> WE DID IT</h4>
  </div>
</div>
 */
