import inView from 'in-view';
import tippy from 'tippy.js';

// set up tooltips
tippy.setDefaults({
    theme: 'faudi',
    arrow: true,
    arrowType: 'sharp',
    duration: 200,
    delay: 500,
    inertia: true,
    animation: 'shift-away',
    zIndex: 4,
    trigger: 'focus click',
    placement: 'top-start',
});

// set up fancybox overlay
$('a.fancybox').fancybox({
    buttons: [
        'zoom',
        'close',
    ],
});

// set up element visibility based on viewport
$('[data-in-view]').attr('data-in-view', false);
inView('[data-in-view]').on('enter', elem => $(elem).attr('data-in-view', true));

$('[data-href]').each(function() {
    const target = $(this).data('linkdata').split(' ')[1];
    const href = $(this).data('href');
    if (href !== '') {
        const $a = $('<a />').attr('href', href).attr('target', target);
        const $parent = $(this).parent();
        const $content = $(this).detach();
        $parent.append($a.append($content));
    }
});

$('.news-item__more').each(function() {
    const $source = $(this).children('.more-btn').first();
    const href = $source.attr('href');
    if (href !== '') {
        const $a = $('<a />').attr('href', href).attr('target', $source.attr('target'));
        const $parent = $(this).parent().parent();
        const $content = $(this).parent('.news-item__text').detach();
        $parent.append($a.append($content));
    }
});
