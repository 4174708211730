const selector = document.querySelector('.quick-contact');
const visibleClass = 'quick-contact--visible';
const closeSelector = document.querySelector('.quick-contact__close');
const removedClass = 'quick-contact--removed';

window.onscroll = () => {
    if (selector.classList.contains(visibleClass)) {
        if ((window.scrollY) < window.innerHeight) {
            selector.classList.remove(visibleClass);
        }
    } else if (!selector.classList.contains(removedClass)) {
        if ((window.scrollY) >= window.innerHeight) {
            selector.classList.add(visibleClass);
        }
    }
};

closeSelector.addEventListener('click', (
    () => {
        selector.classList.remove(visibleClass);
        selector.classList.add(removedClass);
    }
));
