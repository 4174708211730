const items = document.querySelectorAll('.collapsible__item');
items.forEach(item => {
    const head = item.querySelector('.collapsible__head');
    const body = item.querySelector('.collapsible__body');

    $(body).toggle();

    head.addEventListener('click', () => {
        item.classList.toggle('collapsible__item--active');
        $(body).slideToggle();
    });
});

if (sessionStorage.getItem('productUri') !== null) {
    const anchorPoint = sessionStorage.getItem('productUri');
    const anchorElement = $(`.collapsible [href='${anchorPoint}']`).first();
    if (anchorPoint && anchorElement) {
        if ('scrollRestaration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        if (anchorElement.length > 0) {
            anchorElement.parents('.collapsible__item').toggleClass('collapsible__item--active');
            anchorElement.parents('.collapsible__body').slideToggle(0);
            anchorElement[0].scrollIntoView({ block: 'center' });
            sessionStorage.removeItem('productUri');
            if (sessionStorage.getItem('productUri' !== null)) {
                window.history.scrollRestoration = 'auto';
            }
        }
    }
}
