$('.collapsible-country').each((_, component) => {
    $(component).find('.collapsible-country__item').each((__, item) => {
        $(item).find('.collapsible-country__body').hide();
        $(item).on('click', '.collapsible-country__head', () => {
            $(item).find('.collapsible-country__body').slideToggle();
            $(item).toggleClass('collapsible-country__item--active');
        });
    });
});

$('.collapsible-country__head:even').css('background-color', '#dddfe2');
