const items = document.querySelectorAll('.products__item');
items.forEach(item => {
    const link = item.querySelector('.more-btn');
    $(link).append('<span class="products__info--link"></span>');
});
const itemLinks = document.querySelectorAll('.products__info--link');
itemLinks.forEach(itemLink => {
    itemLink.addEventListener('click', event => {
        const elm = event.srcElement;
        const productLink = elm.closest('a');
        const productUri = productLink.getAttribute('href');
        sessionStorage.setItem('productUri', productUri);
    });
});
