function stopVideoOnLeave (component, slide) {
    const $slide = $(component).find('.slick-slide').eq(slide + 1);
    const $video = $slide.find('video');

    if ($video.length) {
        $video.off('ended');
        $video.get(0).pause();
    }
}

function playVideoOnEnter (component, slide) {
    const $slide = $(component).find('.slick-slide').eq(slide + 1);
    const $video = $slide.find('video');

    if ($video.length) {
        const video = $video.get(0);
        video.play();
        $(component).slick('slickPause');
        $(video).one('ended', () => {
            video.currentTime = 0;
            setTimeout(() => $(component).slick('slickNext'), 1000);
        });
    }
}

$('.slider').each((_, component) => {
    $(component)
        .on('init', () => playVideoOnEnter(component, 0))
        .on('beforeChange', (e, slick, currentSlide) => stopVideoOnLeave(component, currentSlide))
        .on('afterChange', (e, slick, currentSlide) => playVideoOnEnter(component, currentSlide))
        .slick({
            adaptiveHeight: false,
            dots: true,
            autoplaySpeed: 3000,
        });
});
