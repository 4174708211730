const $searchSubmit = $('.nav__search__submit');
const $searchInput = $('.nav__search__input');

$searchSubmit.click(e => {
    if (!$searchInput.hasClass('nav__search__input--active')) {
        $searchInput.addClass('nav__search__input--active').focus();
        e.preventDefault();
    } else if ($searchInput.val().length === 0) {
        $searchInput.removeClass('nav__search__input--active');
        e.preventDefault();
    }
});
